@context (devicemotion > 50) {
  body {
    font-size: 24px;
  }
  /* ELEMENTS */
  h1 {
    font-size: 2.5em;
    font-weight: 700;
  }
  h2 {
    font-weight: 600;
  }
  h3 {
    font-weight: 600;
  }
  .lead {
    font-size: 1.375em;
  }
  .breadcrumb {
    font-size: 0.937em;
  }
  /* BUTTONS */
  .btn {
    font-size: 1.25em;
  }
  .btn.btn-read-more {
    font-size: 1.0em;
  }
  .btn.btn-back {
    font-size: 1.0em;
  }
  .btn.btn-sm {
    font-size: 22px;
  }
  .btn-hollow {
    font-size: 32px;
  }
  .btn-dropdown .form-btn {
    font-size: 32px;
  }
  .btn-dropdown .donate-radio span {
    font-size: 1.875em;
  }
  /* MODAL */
  .dudley-modal .modal-content .modal-title {
    font-size: 2.25em;
    font-weight: 700;
    text-align: center;
  }
  .footer-social,
  .header-details {
    font-size: 28px;
  }
  .home-intro .copy p,
  .intro .copy p {
    font-size: 1.375em;
  }
  .home-intro .copy .group-detail,
  .intro .copy .group-detail {
    font-size: 1.2em;
  }
  /* BANNER */
  .simplified-banner .item .carousel-caption .h1 {
    font-size: 28px;
  }
  .boxed-content .detail {
    font-size: 1.2em;
  }
  a.middle-boxes {
    transition: opacity ease 0.5s;
  }
  a.middle-boxes .image-text {
    font-size: 1.5em;
  }
  a.middle-boxes .image-text small {
    font-size: 80%;
  }
  .blue-box .item a.title,
  .green-box .item a.title {
    font-size: 1.25em;
  }
  /* LIST & GRID LAYOUT */
  .list .item .box-content p {
    font-size: 24px;
  }
  .list .item .box-content .name {
    font-size: 28px;
  }
  .grid .item h2 a {
    font-size: 0.647em;
  }
  .grid .title {
    font-size: 28px;
  }
  /* DONATE PAGE */
  .donate-page-box .donate-selection .checkbox-cta .gift-aid {
    font-size: 28px;
  }
  .donate-page-box .donate-selection .donate-radio label {
    font-size: 32px;
  }
  .donate-page-box .donate-selection .donate-radio .gift-aid {
    font-size: 28px;
  }
  .donate-page-box .donate-selection .donate-radio .donate-headers {
    font-size: 32px;
  }
  /* CONTACT PAGE */
  .contact-details {
    font-size: 1.125em;
  }
  /* FOOTER */
  .footer .footer-content .thanks {
    font-size: 32px;
  }
  .second-footer {
    font-size: 0.875em;
  }
  .adaptive-toolbar .content .persona-list li {
    font-size: 22px;
  }
  .adaptive-toolbar .handle {
    font-size: 32px;
  }
  .header-details a.round-icon.text:after {
    content: 'T-';
  }
  .header-details a.round-icon.text {
    background-color: #e81179;
    color: #fff;
  }
}

/*# sourceMappingURL=app-large-text.css.map */
